import { useContext, useMemo } from "react";
import { FormContext } from "../../components/FormContext";
import ImageUploaderCard from "../../components/ImageUploaderCard";
import { RevertableArrayItem } from "../../components/Revertable";
import { getArrayFieldValue, getArrayItemState } from "../../formValues";
import { FieldState } from "../../types";
import { ActivitiesField } from "../types";
import { generatePhotoUrl } from "./CoursePhotoList";

export default function CoursePhotoCard({
  itemIndex,
  formIdentifier,
  setImageUrl,
  planId,
  photoId,
  remove,
  setDeleted,
}: CoursePhotoCardProps) {
  const { form } = useContext(FormContext);
  const arrayName = ActivitiesField.CoursePhotos;
  const { watch } = form;

  const isDeleted =
    getArrayFieldValue({
      form,
      arrayName,
      itemIndex,
      fieldName: ActivitiesField.CoursePhotoIsDeleted,
    }) ?? false;

  const isAdded =
    getArrayItemState({ form, arrayName, itemIndex }) === FieldState.added;

  const imageProps = useMemo(() => {
    return isDeleted
      ? {
          style: {
            opacity: "30%",
          },
        }
      : {};
  }, [isDeleted]);

  return (
    <div className="EditorFaqCardContainer">
      <RevertableArrayItem
        arrayName={arrayName}
        itemIndex={itemIndex}
        remove={remove}
      >
        <div style={{ margin: "0.25rem 0.75rem" }}>
          <ImageUploaderCard
            mediaUrl={watch(
              `${formIdentifier}.${ActivitiesField.CoursePhotoSrc}`
            )}
            setMediaUrl={setImageUrl}
            mutable={!isAdded}
            path={generatePhotoUrl(planId, photoId)}
            imageProps={imageProps}
            isDeleted={isDeleted}
            handleDelete={() => setDeleted(true)}
            undoDelete={() => setDeleted(false)}
          />
        </div>
      </RevertableArrayItem>
    </div>
  );
}

type CoursePhotoCardProps = {
  planId: string;
  photoId: string;
  itemIndex: number;
  setImageUrl: (imageUrl: string | null) => void;
  formIdentifier: string;
  setDeleted: (isDeleted: boolean) => void;
  remove: () => void;
};
