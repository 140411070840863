import { useContext } from "react";
import { Plan } from "../../List";
import ControlledTextField from "../BasicInfo/components/ControlledTextField";
import { FormContext } from "../components/FormContext";
import { getFieldState } from "../formValues";
import { Revertable } from "../components/Revertable";
import { ActivitiesField } from "./types";
import CoursePhotoList from "./components/CoursePhotoList";
import { useFieldArray } from "react-hook-form";

function ActivitiesEditorForm() {
  const { form } = useContext(FormContext);
  const fieldArray = useFieldArray({
    name: ActivitiesField.CoursePhotos,
    control: form.control,
  });

  return (
    <div className="Activities">
      <Revertable fieldName={ActivitiesField.CourseMenu}>
        <ControlledTextField
          name={ActivitiesField.CourseMenu}
          label="コース内容"
          control={form.control}
          multiline
          fullWidth
          className={getFieldState({
            form,
            fieldName: ActivitiesField.CourseMenu,
          })}
        />
      </Revertable>
      <div style={{ marginTop: "2rem" }}>
        <CoursePhotoList planId={form.planId} fieldArray={fieldArray} />
      </div>
    </div>
  );
}

function getDefaultValuesActivities(plan: Plan) {
  return {
    [ActivitiesField.CourseMenu]: plan.json.courseMenu,
    [ActivitiesField.CoursePhotos]: plan.activities[0].coursePhotos
      .slice()
      .sort((a, b) => a.photoIndex - b.photoIndex)
      .map((coursePhoto) => ({
        ...coursePhoto,
        fieldId: coursePhoto.id,
        [ActivitiesField.CoursePhotoIsDeleted]: false,
      })),
  };
}

const activitiesExport = {
  form: ActivitiesEditorForm,
  getDefaultValues: getDefaultValuesActivities,
};
export default activitiesExport;
