import { useRef } from "react";
import { setArrayItemValue } from "../../formValues";
import { ActivitiesField } from "../types";
import { Chip } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { v4 as uuidV4 } from "uuid";
import useUploadImage from "../../hooks/useImageUploader";
import { FieldValues, UseFieldArrayAppend } from "react-hook-form";
import React from "react";
import { Form } from "../../types";
import ImageInput from "../../components/ImageInput";
import { generatePhotoUrl } from "./CoursePhotoList";

export default function AddCoursePhotoButton({
  form,
  fields,
  append,
}: AddCoursePhotoButtonProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const { uploadImage } = useUploadImage();

  return (
    <div>
      <ImageInput
        inputRef={inputRef}
        onChange={async (event: React.ChangeEvent<HTMLInputElement>) => {
          const photoId = uuidV4();
          const photoSrc = await uploadImage(
            event.target.files![0],
            generatePhotoUrl(form.planId, photoId)
          );
          if (photoSrc) {
            append({});
            setArrayItemValue({
              form,
              arrayName: ActivitiesField.CoursePhotos,
              itemIndex: fields.length,
              value: {
                [ActivitiesField.CoursePhotoId]: photoId,
                [ActivitiesField.CoursePhotoIndex]: fields.length,
                [ActivitiesField.CoursePhotoSrc]: photoSrc.mediaUrl,
                [ActivitiesField.CoursePhotoIsDeleted]: false,
              },
            });
          }
        }}
      />
      <Chip
        style={{ marginTop: "1rem", marginBottom: "1rem" }}
        label={"写真を追加"}
        icon={<AddCircleIcon />}
        clickable
        onClick={() => inputRef.current?.click()}
      />
    </div>
  );
}

type AddCoursePhotoButtonProps = {
  form: Form;
  fields: Record<"id", string>[];
  append: UseFieldArrayAppend<FieldValues, ActivitiesField.CoursePhotos>;
};
