import { useEffect, useState } from "react";
import { useMutation, gql } from "@apollo/client";
import { Button, IconButton, Tooltip } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { Reservation } from "..";
import ReservationDetails from "./ReservationDetails";
import { useNotifications } from "../../../../components/Notification";

interface ConfirmReservationProps {
  reservation: Reservation;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const confirmStyle: React.CSSProperties = {
  color: "green",
  fontWeight: "bold",
};

export default function ConfirmReservation({
  reservation,
  setLoading,
}: ConfirmReservationProps) {
  const { showNotification } = useNotifications();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [confirmReservation, { loading, data }] = useMutation(
    CONFIRM_RESERVATION
  );

  useEffect(() => setLoading(loading), [loading, setLoading, data]);

  return (
    <>
      <Tooltip title="予約確定する">
        <IconButton size="small" onClick={() => setDialogOpen(true)}>
          <CheckCircleIcon style={{ color: "green" }} fontSize="inherit" />
        </IconButton>
      </Tooltip>
      <ConfirmDialog />
    </>
  );

  function ConfirmDialog() {
    return (
      <Dialog open={dialogOpen}>
        <DialogContent>
          この予約を<span style={confirmStyle}>確定</span>してもよろしいですか？
          <ReservationDetails
            style={{ margin: "20px 0px" }}
            reservation={reservation}
          />
          <span style={confirmStyle}>「はい」</span>
          をご選択頂くと、ご予約者の登録メールアドレスへ予約確定メールが送信されます。
        </DialogContent>
        <DialogActions>
          <Button
            style={{ color: "#000" }}
            onClick={() => setDialogOpen(false)}
          >
            いいえ
          </Button>
          <Button
            style={{ color: "green" }}
            onClick={() => handleConfirmReservation()}
          >
            はい
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  function handleConfirmReservation() {
    confirmReservation({
      variables: { reservationId: reservation.id },
      refetchQueries: ["GetBookingsListData"],
    })
      .then(() =>
        showNotification({
          message: "予約確定が完了しました！",
          severity: "success",
        })
      )
      .catch(() =>
        showNotification({
          message: "予約確定に失敗しました！",
          severity: "error",
        })
      );

    setDialogOpen(false);
  }
}

const CONFIRM_RESERVATION = gql`
  mutation ConfirmReservation($reservationId: ID!) {
    confirmReservation(input: { reservationId: $reservationId }) {
      success
    }
  }
`;
