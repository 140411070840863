import { Tab, Tabs } from "@mui/material";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import FlareIcon from "@mui/icons-material/Flare";
import InfoIcon from "@mui/icons-material/Info";
import "./EditorSidebar.css";

const tabLabels = [
  { label: "基本情報", Icon: InfoIcon },
  { label: "ハイライト", Icon: FlareIcon },
  { label: "メニュー内容", Icon: RestaurantIcon },
  { label: "よくある質問", Icon: QuestionAnswerIcon },
];

export default function EditorSidebar({
  currentTab,
  setCurrentTab,
}: EditorSidebarProps) {
  const handleChange = (event: React.SyntheticEvent, newTab: number) => {
    setCurrentTab(newTab);
  };

  return (
    <Tabs
      className="EditorSidebar"
      orientation="vertical"
      variant="scrollable"
      value={currentTab}
      onChange={handleChange}
    >
      {tabLabels.map(({ label, Icon }, i) => (
        <Tab
          key={i}
          label={
            <div className="EditorSidebarTab">
              <Icon />
              <div>{label}</div>
            </div>
          }
        />
      ))}
    </Tabs>
  );
}

interface EditorSidebarProps {
  currentTab: number;
  setCurrentTab: (currentTab: number) => void;
}
