import { createContext, Dispatch, SetStateAction, useState } from "react";
import { Form } from "../types";

interface FormContextType {
  form: Form;
  numUploading: number;
  setNumUploading: Dispatch<SetStateAction<number>>;
}

export const FormContext = createContext<FormContextType>({
  // @ts-ignore
  form: undefined,
  numUploading: 0,
  setNumUploading: () => {},
});

export function FormProvider({
  form,
  children,
}: React.PropsWithChildren<Pick<FormContextType, "form">>) {
  const [numUploading, setNumUploading] = useState(0);
  return (
    <FormContext.Provider value={{ form, numUploading, setNumUploading }}>
      {children}
    </FormContext.Provider>
  );
}
