import FormControl from "@mui/material/FormControl";

import TextField from "../../../components/TextField";

export default function TableToolbar(props: TableToolBarProps) {
  return (
    <div style={{ marginTop: "10px", marginRight: "10px", float: "right" }}>
      <FormControl>
        <TextField
          size="small"
          label="検索"
          value={props.search.value ? props.search.value : ""}
          onChange={(e) => props.search.set(e.target.value)}
        />
      </FormControl>
    </div>
  );
}

interface TableToolBarProps {
  search: {
    value: string | null;
    set: (value: string | null) => void;
  };
}
