import { useCallback, useEffect, useState } from "react";
import { Plan } from "..";
import VenueMenu from "./VenueMenu";
import SortablePlanList from "./SortablePlanList";
import { gql, useMutation, useQuery, useApolloClient } from "@apollo/client";
import { CircularProgress, Paper } from "@mui/material";
import { formatDate, getStartOfWeek } from "../../../../helpers/date-functions";
import EditNotifications from "./EditNotifications";

export interface VenueIdAndName {
  id: string;
  name: string;
}

interface DndTableProps {
  venues: VenueIdAndName[];
}

export default function SortablePlanTable({ venues }: DndTableProps) {
  const [plans, setPlans] = useState<Plan[]>([]);
  const [venue, setVenue] = useState(venues[0]);
  const apolloClient = useApolloClient();
  const {
    error: plansError,
    data: plansData,
    loading: plansLoading,
  } = useQuery<any>(GET_PLANS, {
    variables: {
      venueId: venue.id,
      input: {
        // 12 weeks
        from: formatDate(
          getStartOfWeek(new Date(Date.now() - 7 * 11 * 24 * 60 * 60 * 1000))
        ),
      },
    },
  });

  useEffect(() => {
    // only update if the data is new
    if (plans.length === 0 || plans[0].activities[0].venue.id !== venue.id) {
      const newPlans: Plan[] =
        plansData?.plans.filter((plan: Plan) => plan.publishedPlan !== null) ??
        [];
      setPlans(
        [...newPlans].sort((a, b) => {
          // sort by planOrder if not null, else by planId
          if (a.planOrder === null && b.planOrder === null)
            return a.id.localeCompare(b.id);
          if (a.planOrder === null) return -1;
          if (b.planOrder === null) return 1;
          return a.planOrder - b.planOrder;
        })
      );
    }
  }, [plans, plansData, venue]);

  const [savePlanOrder] = useMutation(SAVE_PLAN_ORDER);

  const onChange = useCallback(
    (reorderedPlans: Plan[]) => {
      const newPlans = reorderedPlans.map((plan, i) => {
        return { ...plan, planOrder: i };
      });

      apolloClient.cache.writeQuery({
        query: GET_PLANS,
        variables: {
          venueId: venue.id,
        },
        data: {
          plans: newPlans,
        },
      });

      savePlanOrder({
        variables: {
          input: {
            planIdAndOrder: newPlans.map((plan) => ({
              planId: plan.id,
              planOrder: plan.planOrder,
            })),
          },
        },
      });

      setPlans(newPlans);
    },
    [venue, apolloClient.cache, savePlanOrder]
  );

  if (plansError) return <>不具合が発生しました</>;

  return (
    <Paper sx={{ width: "100%", mb: 2 }}>
      <div
        className="VenueMenu"
        style={{ display: "flex", flexDirection: "row" }}
      >
        {plansLoading && <CircularProgress />}
        <VenueMenu venues={venues} venue={venue} setVenue={setVenue} />
        <EditNotifications />
      </div>
      <SortablePlanList
        venueName={venue.name}
        plans={plans}
        onChange={onChange}
      />
    </Paper>
  );
}

export const SAVE_PLAN_ORDER = gql`
  mutation savePlanOrdering($input: SavePlanOrderingInput!) {
    savePlanOrdering(input: $input) {
      success
    }
  }
`;

const GET_PLANS = gql`
  query GetPlans($venueId: ID, $input: BookingsInput!) {
    plans(filter: { venueId: $venueId, fetchAllIfPermitted: false }) {
      id
      name
      firstPublishedAt
      planOrder
      activities {
        id
        venue {
          id
          name
        }
        priceTypes {
          name
          amount
          currencyCode
          active
        }
      }
      publishedPlan {
        id
      }
      bookings(input: $input) {
        bookings
        rejectionRate
        rejections
      }
    }
  }
`;
